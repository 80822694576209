<app-header></app-header>
<!-- Carousel Start -->
<div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
  <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
      <!-- <div class="carousel-indicators">
          <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
              <img class="img-fluid" src="assets/webpage/carousel-1.jpg" alt="Image">
          </button>
          <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="1" aria-label="Slide 2">
              <img class="img-fluid" src="assets/webpage/carousel-2.jpg" alt="Image">
          </button>
          <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="2" aria-label="Slide 3">
              <img class="img-fluid" src="assets/webpage/carousel-3.jpg" alt="Image">
          </button>
      </div> -->
      <div class="carousel-inner">
          <div class="carousel-item active">
              <img class="w-100" src="assets/webpage/carousel-1.jpg" alt="Image">
              <div class="carousel-caption">
                  <div class="p-3" style="max-width: 900px;">
                      <h4 class="text-white text-uppercase mb-4 animated zoomIn">Desarrollo Rápido y Eficiente</h4>
                      <h1 class="display-1 text-white mb-0 animated zoomIn">La integración continua como metodología operativa</h1>
                  </div>
              </div>
          </div>
          <div class="carousel-item">
              <img class="w-100" src="assets/webpage/carousel-2.jpg" alt="Image">
              <div class="carousel-caption">
                  <div class="p-3" style="max-width: 900px;">
                      <h4 class="text-white text-uppercase mb-4 animated zoomIn">Gestion 360º</h4>
                      <h1 class="display-1 text-white mb-0 animated zoomIn">Capacitados para gestionar proyectos desde sus inicios</h1>
                  </div>
              </div>
          </div>
          <div class="carousel-item">
              <img class="w-100" src="assets/webpage/carousel-3.jpg" alt="Image">
              <div class="carousel-caption">
                  <div class="p-3" style="max-width: 900px;">
                      <h4 class="text-white text-uppercase mb-4 animated zoomIn">Equipo enfocado en el resultado</h4>
                      <h1 class="display-1 text-white mb-0 animated zoomIn">Decididos a dar el máximo en cada etapa</h1>
                  </div>
              </div>
          </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
      </button>
  </div>
</div>
<!-- Carousel End -->
<div class="container-xxl py-5">
  <div class="container">
    <div class="row g-4">
      <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <div class="fact-item bg-light rounded text-center h-100 p-5">
          <i class="fa fa-certificate fa-4x text-primary mb-4"></i>
          <h5 class="mb-3">Años de Experiencia</h5>
          <h1 class="display-5 mb-0" data-toggle="counter-up">7</h1>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
        <div class="fact-item bg-light rounded text-center h-100 p-5">
          <i class="fa fa-users-cog fa-4x text-primary mb-4"></i>
          <h5 class="mb-3">Miembros en el equipo</h5>
          <h1 class="display-5 mb-0" data-toggle="counter-up">3</h1>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
        <div class="fact-item bg-light rounded text-center h-100 p-5">
          <i class="fa fa-users fa-4x text-primary mb-4"></i>
          <h5 class="mb-3">Clientes Satisfechos</h5>
          <h1 class="display-5 mb-0" data-toggle="counter-up">12</h1>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
        <div class="fact-item bg-light rounded text-center h-100 p-5">
          <i class="fa fa-check fa-4x text-primary mb-4"></i>
          <h5 class="mb-3">Proyectos Realizados</h5>
          <h1 class="display-5 mb-0" data-toggle="counter-up">15</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Facts End -->

<!-- About Start -->
<div class="container-xxl py-5">
  <div class="container">
    <div class="row g-5">
      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
        <div class="img-border">
          <img class="img-fluid" src="assets/webpage/about.jpg" alt="" />
        </div>
      </div>
      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
        <div class="h-100">
          <h6 class="section-title bg-white text-start text-primary pe-3">Sobre Nosotros</h6>
          <h1 class="display-6 mb-4">Lideres en el <span class="text-primary">Desarrollo </span> de Aplicaciones</h1>
          <p>Somos una empresa joven de Internet con sede en la Comunidad de Madrid. Como apasionados de la tecnología, nuestro objetivo es siempre ofrecer el mejor resultado basado en tecnologías modernas, a la vez que mantener un trato cercano con los clientes para juntos acercarnos a una mejor solución final.</p>
          
          <div class="d-flex align-items-center mb-4 pb-2">
            <!-- <img class="flex-shrink-0 rounded-circle" src="assets/webpage/team-1.jpg" alt="" style="width: 50px; height: 50px;" /> -->
            <div class="ps-4">
              <h6>Sergio Casado</h6>
              <small>CTO & Founder</small>
            </div>
          </div>
          <!-- <a class="btn btn-primary rounded-pill py-3 px-5" href="">Read More</a> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- About End -->

<!-- Service Start -->
<div class="container-xxl py-5">
  <div class="container">
    <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
      <h6 class="section-title bg-white text-center text-primary px-3">Servicios</h6>
      <h1 class="display-6 mb-4">Las secciones en las que nos enfocamos</h1>
    </div>
    <div class="row g-4">
      <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <a class="service-item d-block rounded text-center h-100 p-4" href="">
          <img class="img-fluid rounded mb-4" src="assets/webpage/service-1.jpg" alt="" />
          <h4 class="mb-0">Gestion de Infraestructura</h4>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
        <a class="service-item d-block rounded text-center h-100 p-4" href="">
          <img class="img-fluid rounded mb-4" src="assets/webpage/service-2.jpg" alt="" />
          <h4 class="mb-0">Desarrollo de Aplicaciones</h4>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
        <a class="service-item d-block rounded text-center h-100 p-4" href="">
          <img class="img-fluid rounded mb-4" src="assets/webpage/service-3.jpg" alt="" />
          <h4 class="mb-0">Optimizacion del SEO Onpage</h4>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <a class="service-item d-block rounded text-center h-100 p-4" href="">
          <img class="img-fluid rounded mb-4" src="assets/webpage/service-4.jpg" alt="" />
          <h4 class="mb-0">Cloud Computing</h4>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
        <a class="service-item d-block rounded text-center h-100 p-4" href="">
          <img class="img-fluid rounded mb-4" src="assets/webpage/service-5.jpg" alt="" />
          <h4 class="mb-0">Gestion de Hosting and Email</h4>
        </a>
      </div>
      <!--<div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
        <a class="service-item d-block rounded text-center h-100 p-4" href="">
          <img class="img-fluid rounded mb-4" src="assets/webpage/service-6.jpg" alt="" />
          <h4 class="mb-0">PPC Advertising</h4>
        </a>
      </div> -->
    </div>
  </div>
</div>
<!-- Service End -->

<app-footer></app-footer>
