import { Component, OnInit } from '@angular/core';
import { ComponentesModule } from '../../componentes/componentes.module';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-index',
  standalone: true,
  imports: [ComponentesModule],
  templateUrl: './index.component.html',
  styleUrl: './index.component.css'
})
export class IndexComponent implements OnInit{
    
    constructor(private meta: Meta, private title: Title) {
        
    }
    
    ngOnInit(): void {
        this.generateTags()
    }
    
    generateTags(){
        this.title.setTitle("Networkcode Tech Solution - Consultoria");
            this.meta.updateTag(
            {
            name: 'description', 
            content: 'Consultoria informatica de madrid, con mas 8 años de experiencia en eñ desarrollo de aplicaciones informaticas: microservicios, public cloud, hosting, custom apps y muchas mas' 
            }
        );
        this.meta.addTags([  
            { name: 'keywords', content: 'consultoria, informatica, cloud, hosting, dominios, dinahosting, ovh' },  
            { name: 'robots', content: 'index, follow' },  
            { name: 'writer', content: 'Networkcode Tech Solution SL' }
        ]);
        // Open Graph Meta Tags
        this.meta.addTag({ property: 'og:title', content: 'Networkcode Tech Solution - Consultoria' });
        this.meta.addTag({ property: 'og:description', content: 'Consultoria informatica de madrid, con mas 8 años de experiencia en eñ desarrollo de aplicaciones informaticas: microservicios, public cloud, hosting, custom apps y muchas mas' });
        this.meta.addTag({ property: 'og:image', content: 'assets/logo.png' });
    }

}
